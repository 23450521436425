@charset "UTF-8";
/*
  CONFIG
*/
/*
  Webfont
*/
/*
  Typographic Setup
*/
/*
  Defaults for boot/reboot.scss
*/
/*
  zindex list
*/
/*
  BOOT
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: Ethos, serif;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  text-align: left;
  background-color: #FFFFFF;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6, p {
  hyphens: false;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.1;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin: 0;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0;
}

b,
strong {
  font-weight: 500;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 80%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: inherit;
  text-decoration: underline;
  background-color: transparent;
}
a:hover {
  color: inherit;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 1em;
  padding-bottom: 1em;
  color: inherit;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/*
  Webfont Implementation
*/
body {
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "Ethos";
  src: url("/assets/fonts/ethos/313175_20_0.eot");
  /* IE9 Compat Modes */
  src: url("/assets/fonts/ethos/313175_20_0.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/ethos/313175_20_0.woff2") format("woff2"), url("/assets/fonts/ethos/313175_20_0.woff") format("woff"), url("/assets/fonts/ethos/313175_20_0.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-display: fallback;
}
@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/montserrat/Montserrat-Medium.eot");
  /* IE9 Compat Modes */
  src: url("/assets/fonts/montserrat/Montserrat-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/montserrat/Montserrat-Medium.woff2") format("woff2"), url("/assets/fonts/montserrat/Montserrat-Medium.woff") format("woff"), url("/assets/fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
  font-display: fallback;
}
/*
  UTILITIES
*/
/*
  Font Weights
*/
.c-black {
  color: #000000;
}

.bg-black {
  background-color: #000000;
}

.c-sand {
  color: #807D71;
}

.bg-sand {
  background-color: #807D71;
}

.c-white {
  color: #FFFFFF;
}

.bg-white {
  background-color: #FFFFFF;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-align-center {
  align-items: center;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

.grid {
  display: flex;
  flex-wrap: wrap;
}

.grid-center {
  align-items: center;
}

.offset-0 {
  margin-left: 0;
}

/* Column */
.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

/* Offset */
.offset-1 {
  margin-left: 8.3333333333%;
}

/* Column */
.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

/* Offset */
.offset-2 {
  margin-left: 16.6666666667%;
}

/* Column */
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

/* Offset */
.offset-3 {
  margin-left: 25%;
}

/* Column */
.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

/* Offset */
.offset-4 {
  margin-left: 33.3333333333%;
}

/* Column */
.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

/* Offset */
.offset-5 {
  margin-left: 41.6666666667%;
}

/* Column */
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

/* Offset */
.offset-6 {
  margin-left: 50%;
}

/* Column */
.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

/* Offset */
.offset-7 {
  margin-left: 58.3333333333%;
}

/* Column */
.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

/* Offset */
.offset-8 {
  margin-left: 66.6666666667%;
}

/* Column */
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

/* Offset */
.offset-9 {
  margin-left: 75%;
}

/* Column */
.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

/* Offset */
.offset-10 {
  margin-left: 83.3333333333%;
}

/* Column */
.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

/* Offset */
.offset-11 {
  margin-left: 91.6666666667%;
}

/* Column */
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

/* Offset */
.offset-12 {
  margin-left: 100%;
}

@media (min-width: 576px) {
  .sm\:offset-0 {
    margin-left: 0;
  }

  /* Column */
  .sm\:col-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  /* Offset */
  .sm\:offset-1 {
    margin-left: 8.3333333333%;
  }

  /* Column */
  .sm\:col-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  /* Offset */
  .sm\:offset-2 {
    margin-left: 16.6666666667%;
  }

  /* Column */
  .sm\:col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* Offset */
  .sm\:offset-3 {
    margin-left: 25%;
  }

  /* Column */
  .sm\:col-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  /* Offset */
  .sm\:offset-4 {
    margin-left: 33.3333333333%;
  }

  /* Column */
  .sm\:col-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  /* Offset */
  .sm\:offset-5 {
    margin-left: 41.6666666667%;
  }

  /* Column */
  .sm\:col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* Offset */
  .sm\:offset-6 {
    margin-left: 50%;
  }

  /* Column */
  .sm\:col-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  /* Offset */
  .sm\:offset-7 {
    margin-left: 58.3333333333%;
  }

  /* Column */
  .sm\:col-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  /* Offset */
  .sm\:offset-8 {
    margin-left: 66.6666666667%;
  }

  /* Column */
  .sm\:col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  /* Offset */
  .sm\:offset-9 {
    margin-left: 75%;
  }

  /* Column */
  .sm\:col-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  /* Offset */
  .sm\:offset-10 {
    margin-left: 83.3333333333%;
  }

  /* Column */
  .sm\:col-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  /* Offset */
  .sm\:offset-11 {
    margin-left: 91.6666666667%;
  }

  /* Column */
  .sm\:col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* Offset */
  .sm\:offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 768px) {
  .md\:offset-0 {
    margin-left: 0;
  }

  /* Column */
  .md\:col-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  /* Offset */
  .md\:offset-1 {
    margin-left: 8.3333333333%;
  }

  /* Column */
  .md\:col-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  /* Offset */
  .md\:offset-2 {
    margin-left: 16.6666666667%;
  }

  /* Column */
  .md\:col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* Offset */
  .md\:offset-3 {
    margin-left: 25%;
  }

  /* Column */
  .md\:col-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  /* Offset */
  .md\:offset-4 {
    margin-left: 33.3333333333%;
  }

  /* Column */
  .md\:col-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  /* Offset */
  .md\:offset-5 {
    margin-left: 41.6666666667%;
  }

  /* Column */
  .md\:col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* Offset */
  .md\:offset-6 {
    margin-left: 50%;
  }

  /* Column */
  .md\:col-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  /* Offset */
  .md\:offset-7 {
    margin-left: 58.3333333333%;
  }

  /* Column */
  .md\:col-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  /* Offset */
  .md\:offset-8 {
    margin-left: 66.6666666667%;
  }

  /* Column */
  .md\:col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  /* Offset */
  .md\:offset-9 {
    margin-left: 75%;
  }

  /* Column */
  .md\:col-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  /* Offset */
  .md\:offset-10 {
    margin-left: 83.3333333333%;
  }

  /* Column */
  .md\:col-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  /* Offset */
  .md\:offset-11 {
    margin-left: 91.6666666667%;
  }

  /* Column */
  .md\:col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* Offset */
  .md\:offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 992px) {
  .lg\:offset-0 {
    margin-left: 0;
  }

  /* Column */
  .lg\:col-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  /* Offset */
  .lg\:offset-1 {
    margin-left: 8.3333333333%;
  }

  /* Column */
  .lg\:col-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  /* Offset */
  .lg\:offset-2 {
    margin-left: 16.6666666667%;
  }

  /* Column */
  .lg\:col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* Offset */
  .lg\:offset-3 {
    margin-left: 25%;
  }

  /* Column */
  .lg\:col-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  /* Offset */
  .lg\:offset-4 {
    margin-left: 33.3333333333%;
  }

  /* Column */
  .lg\:col-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  /* Offset */
  .lg\:offset-5 {
    margin-left: 41.6666666667%;
  }

  /* Column */
  .lg\:col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* Offset */
  .lg\:offset-6 {
    margin-left: 50%;
  }

  /* Column */
  .lg\:col-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  /* Offset */
  .lg\:offset-7 {
    margin-left: 58.3333333333%;
  }

  /* Column */
  .lg\:col-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  /* Offset */
  .lg\:offset-8 {
    margin-left: 66.6666666667%;
  }

  /* Column */
  .lg\:col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  /* Offset */
  .lg\:offset-9 {
    margin-left: 75%;
  }

  /* Column */
  .lg\:col-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  /* Offset */
  .lg\:offset-10 {
    margin-left: 83.3333333333%;
  }

  /* Column */
  .lg\:col-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  /* Offset */
  .lg\:offset-11 {
    margin-left: 91.6666666667%;
  }

  /* Column */
  .lg\:col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* Offset */
  .lg\:offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1200px) {
  .xl\:offset-0 {
    margin-left: 0;
  }

  /* Column */
  .xl\:col-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  /* Offset */
  .xl\:offset-1 {
    margin-left: 8.3333333333%;
  }

  /* Column */
  .xl\:col-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  /* Offset */
  .xl\:offset-2 {
    margin-left: 16.6666666667%;
  }

  /* Column */
  .xl\:col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* Offset */
  .xl\:offset-3 {
    margin-left: 25%;
  }

  /* Column */
  .xl\:col-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  /* Offset */
  .xl\:offset-4 {
    margin-left: 33.3333333333%;
  }

  /* Column */
  .xl\:col-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  /* Offset */
  .xl\:offset-5 {
    margin-left: 41.6666666667%;
  }

  /* Column */
  .xl\:col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* Offset */
  .xl\:offset-6 {
    margin-left: 50%;
  }

  /* Column */
  .xl\:col-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  /* Offset */
  .xl\:offset-7 {
    margin-left: 58.3333333333%;
  }

  /* Column */
  .xl\:col-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  /* Offset */
  .xl\:offset-8 {
    margin-left: 66.6666666667%;
  }

  /* Column */
  .xl\:col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  /* Offset */
  .xl\:offset-9 {
    margin-left: 75%;
  }

  /* Column */
  .xl\:col-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  /* Offset */
  .xl\:offset-10 {
    margin-left: 83.3333333333%;
  }

  /* Column */
  .xl\:col-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  /* Offset */
  .xl\:offset-11 {
    margin-left: 91.6666666667%;
  }

  /* Column */
  .xl\:col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* Offset */
  .xl\:offset-12 {
    margin-left: 100%;
  }
}
.grid {
  margin: -10px;
}

.grid [class*=col-] {
  padding: 10px;
}

@media (min-width: 768px) {
  .grid {
    margin: -15px;
  }

  .grid [class*=col-] {
    padding: 15px;
  }
}
@media (min-width: 992px) {
  .grid {
    margin: -20px;
  }

  .grid [class*=col-] {
    padding: 20px;
  }
}
/* Order */
.order-1 {
  order: 1;
}

/* Order */
.order-2 {
  order: 2;
}

/* Order */
.order-3 {
  order: 3;
}

/* Order */
.order-4 {
  order: 4;
}

/* Order */
.order-5 {
  order: 5;
}

/* Order */
.order-6 {
  order: 6;
}

@media (min-width: 576px) {
  /* Order */
  .sm\:order-1 {
    order: 1;
  }

  /* Order */
  .sm\:order-2 {
    order: 2;
  }

  /* Order */
  .sm\:order-3 {
    order: 3;
  }

  /* Order */
  .sm\:order-4 {
    order: 4;
  }

  /* Order */
  .sm\:order-5 {
    order: 5;
  }

  /* Order */
  .sm\:order-6 {
    order: 6;
  }
}
@media (min-width: 768px) {
  /* Order */
  .md\:order-1 {
    order: 1;
  }

  /* Order */
  .md\:order-2 {
    order: 2;
  }

  /* Order */
  .md\:order-3 {
    order: 3;
  }

  /* Order */
  .md\:order-4 {
    order: 4;
  }

  /* Order */
  .md\:order-5 {
    order: 5;
  }

  /* Order */
  .md\:order-6 {
    order: 6;
  }
}
@media (min-width: 992px) {
  /* Order */
  .lg\:order-1 {
    order: 1;
  }

  /* Order */
  .lg\:order-2 {
    order: 2;
  }

  /* Order */
  .lg\:order-3 {
    order: 3;
  }

  /* Order */
  .lg\:order-4 {
    order: 4;
  }

  /* Order */
  .lg\:order-5 {
    order: 5;
  }

  /* Order */
  .lg\:order-6 {
    order: 6;
  }
}
@media (min-width: 1200px) {
  /* Order */
  .xl\:order-1 {
    order: 1;
  }

  /* Order */
  .xl\:order-2 {
    order: 2;
  }

  /* Order */
  .xl\:order-3 {
    order: 3;
  }

  /* Order */
  .xl\:order-4 {
    order: 4;
  }

  /* Order */
  .xl\:order-5 {
    order: 5;
  }

  /* Order */
  .xl\:order-6 {
    order: 6;
  }
}
@media (max-width: 575.98px) {
  .xs\:order-first {
    order: -1;
  }

  .xs\:order-last {
    order: 9999;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .sm\:order-first {
    order: -1;
  }

  .sm\:order-last {
    order: 9999;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .md\:order-first {
    order: -1;
  }

  .md\:order-last {
    order: 9999;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .lg\:order-first {
    order: -1;
  }

  .lg\:order-last {
    order: 9999;
  }
}
@media (min-width: 1200px) {
  .xl\:order-first {
    order: -1;
  }

  .xl\:order-last {
    order: 9999;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

/*
  Spacers
*/
/*
  MARGIN – 0
*/
.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

/*
  PADDING – 0
*/
.p-0 {
  padding: 0;
}

.pt-0 {
  padding-top: 0;
}

.pr-0 {
  padding-right: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

/*
  MARGIN – md
*/
.m-md {
  margin: 1rem;
}

.mt-md {
  margin-top: 1rem;
}

.mr-md {
  margin-right: 1rem;
}

.mb-md {
  margin-bottom: 1rem;
}

.ml-md {
  margin-left: 1rem;
}

.mx-md {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-md {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/*
  PADDING – md
*/
.p-md {
  padding: 1rem;
}

.pt-md {
  padding-top: 1rem;
}

.pr-md {
  padding-right: 1rem;
}

.pb-md {
  padding-bottom: 1rem;
}

.pl-md {
  padding-left: 1rem;
}

.px-md {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-md {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/*
  MARGIN – lg
*/
.m-lg {
  margin: 2rem;
}

.mt-lg {
  margin-top: 2rem;
}

.mr-lg {
  margin-right: 2rem;
}

.mb-lg {
  margin-bottom: 2rem;
}

.ml-lg {
  margin-left: 2rem;
}

.mx-lg {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-lg {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/*
  PADDING – lg
*/
.p-lg {
  padding: 2rem;
}

.pt-lg {
  padding-top: 2rem;
}

.pr-lg {
  padding-right: 2rem;
}

.pb-lg {
  padding-bottom: 2rem;
}

.pl-lg {
  padding-left: 2rem;
}

.px-lg {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-lg {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/*
  MARGIN – xl
*/
.m-xl {
  margin: 4.5rem;
}

.mt-xl {
  margin-top: 4.5rem;
}

.mr-xl {
  margin-right: 4.5rem;
}

.mb-xl {
  margin-bottom: 4.5rem;
}

.ml-xl {
  margin-left: 4.5rem;
}

.mx-xl {
  margin-left: 4.5rem;
  margin-right: 4.5rem;
}

.my-xl {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}

/*
  PADDING – xl
*/
.p-xl {
  padding: 4.5rem;
}

.pt-xl {
  padding-top: 4.5rem;
}

.pr-xl {
  padding-right: 4.5rem;
}

.pb-xl {
  padding-bottom: 4.5rem;
}

.pl-xl {
  padding-left: 4.5rem;
}

.px-xl {
  padding-left: 4.5rem;
  padding-right: 4.5rem;
}

.py-xl {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

/*
  MARGIN – p-lg
*/
.m-p-lg {
  margin: 4.5%;
}

.mt-p-lg {
  margin-top: 4.5%;
}

.mr-p-lg {
  margin-right: 4.5%;
}

.mb-p-lg {
  margin-bottom: 4.5%;
}

.ml-p-lg {
  margin-left: 4.5%;
}

.mx-p-lg {
  margin-left: 4.5%;
  margin-right: 4.5%;
}

.my-p-lg {
  margin-top: 4.5%;
  margin-bottom: 4.5%;
}

/*
  PADDING – p-lg
*/
.p-p-lg {
  padding: 4.5%;
}

.pt-p-lg {
  padding-top: 4.5%;
}

.pr-p-lg {
  padding-right: 4.5%;
}

.pb-p-lg {
  padding-bottom: 4.5%;
}

.pl-p-lg {
  padding-left: 4.5%;
}

.px-p-lg {
  padding-left: 4.5%;
  padding-right: 4.5%;
}

.py-p-lg {
  padding-top: 4.5%;
  padding-bottom: 4.5%;
}

/*
  MARGIN – p-xl
*/
.m-p-xl {
  margin: 7.5%;
}

.mt-p-xl {
  margin-top: 7.5%;
}

.mr-p-xl {
  margin-right: 7.5%;
}

.mb-p-xl {
  margin-bottom: 7.5%;
}

.ml-p-xl {
  margin-left: 7.5%;
}

.mx-p-xl {
  margin-left: 7.5%;
  margin-right: 7.5%;
}

.my-p-xl {
  margin-top: 7.5%;
  margin-bottom: 7.5%;
}

/*
  PADDING – p-xl
*/
.p-p-xl {
  padding: 7.5%;
}

.pt-p-xl {
  padding-top: 7.5%;
}

.pr-p-xl {
  padding-right: 7.5%;
}

.pb-p-xl {
  padding-bottom: 7.5%;
}

.pl-p-xl {
  padding-left: 7.5%;
}

.px-p-xl {
  padding-left: 7.5%;
  padding-right: 7.5%;
}

.py-p-xl {
  padding-top: 7.5%;
  padding-bottom: 7.5%;
}

/*
  MARGIN – push
*/
.m-push {
  margin: calc(5vw + 75px) !important;
}

.mt-push {
  margin-top: calc(5vw + 75px) !important;
}

.mr-push {
  margin-right: calc(5vw + 75px) !important;
}

.mb-push {
  margin-bottom: calc(5vw + 75px) !important;
}

.ml-push {
  margin-left: calc(5vw + 75px) !important;
}

.mx-push {
  margin-left: calc(5vw + 75px) !important;
  margin-right: calc(5vw + 75px) !important;
}

.my-push {
  margin-top: calc(5vw + 75px) !important;
  margin-bottom: calc(5vw + 75px) !important;
}

/*
  PADDING – push
*/
.p-push {
  padding: calc(5vw + 75px) !important;
}

.pt-push {
  padding-top: calc(5vw + 75px) !important;
}

.pr-push {
  padding-right: calc(5vw + 75px) !important;
}

.pb-push {
  padding-bottom: calc(5vw + 75px) !important;
}

.pl-push {
  padding-left: calc(5vw + 75px) !important;
}

.px-push {
  padding-left: calc(5vw + 75px) !important;
  padding-right: calc(5vw + 75px) !important;
}

.py-push {
  padding-top: calc(5vw + 75px) !important;
  padding-bottom: calc(5vw + 75px) !important;
}

@media (min-width: 576px) {
  /*
    MARGIN – 0
  */
  .sm\:m-0 {
    margin: 0;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mr-0 {
    margin-right: 0;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:ml-0 {
    margin-left: 0;
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  /*
    PADDING – 0
  */
  .sm\:p-0 {
    padding: 0;
  }

  .sm\:pt-0 {
    padding-top: 0;
  }

  .sm\:pr-0 {
    padding-right: 0;
  }

  .sm\:pb-0 {
    padding-bottom: 0;
  }

  .sm\:pl-0 {
    padding-left: 0;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  /*
    MARGIN – md
  */
  .sm\:m-md {
    margin: 1rem;
  }

  .sm\:mt-md {
    margin-top: 1rem;
  }

  .sm\:mr-md {
    margin-right: 1rem;
  }

  .sm\:mb-md {
    margin-bottom: 1rem;
  }

  .sm\:ml-md {
    margin-left: 1rem;
  }

  .sm\:mx-md {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm\:my-md {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  /*
    PADDING – md
  */
  .sm\:p-md {
    padding: 1rem;
  }

  .sm\:pt-md {
    padding-top: 1rem;
  }

  .sm\:pr-md {
    padding-right: 1rem;
  }

  .sm\:pb-md {
    padding-bottom: 1rem;
  }

  .sm\:pl-md {
    padding-left: 1rem;
  }

  .sm\:px-md {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:py-md {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  /*
    MARGIN – lg
  */
  .sm\:m-lg {
    margin: 2rem;
  }

  .sm\:mt-lg {
    margin-top: 2rem;
  }

  .sm\:mr-lg {
    margin-right: 2rem;
  }

  .sm\:mb-lg {
    margin-bottom: 2rem;
  }

  .sm\:ml-lg {
    margin-left: 2rem;
  }

  .sm\:mx-lg {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sm\:my-lg {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  /*
    PADDING – lg
  */
  .sm\:p-lg {
    padding: 2rem;
  }

  .sm\:pt-lg {
    padding-top: 2rem;
  }

  .sm\:pr-lg {
    padding-right: 2rem;
  }

  .sm\:pb-lg {
    padding-bottom: 2rem;
  }

  .sm\:pl-lg {
    padding-left: 2rem;
  }

  .sm\:px-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:py-lg {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  /*
    MARGIN – xl
  */
  .sm\:m-xl {
    margin: 4.5rem;
  }

  .sm\:mt-xl {
    margin-top: 4.5rem;
  }

  .sm\:mr-xl {
    margin-right: 4.5rem;
  }

  .sm\:mb-xl {
    margin-bottom: 4.5rem;
  }

  .sm\:ml-xl {
    margin-left: 4.5rem;
  }

  .sm\:mx-xl {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }

  .sm\:my-xl {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  /*
    PADDING – xl
  */
  .sm\:p-xl {
    padding: 4.5rem;
  }

  .sm\:pt-xl {
    padding-top: 4.5rem;
  }

  .sm\:pr-xl {
    padding-right: 4.5rem;
  }

  .sm\:pb-xl {
    padding-bottom: 4.5rem;
  }

  .sm\:pl-xl {
    padding-left: 4.5rem;
  }

  .sm\:px-xl {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }

  .sm\:py-xl {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  /*
    MARGIN – p-lg
  */
  .sm\:m-p-lg {
    margin: 4.5%;
  }

  .sm\:mt-p-lg {
    margin-top: 4.5%;
  }

  .sm\:mr-p-lg {
    margin-right: 4.5%;
  }

  .sm\:mb-p-lg {
    margin-bottom: 4.5%;
  }

  .sm\:ml-p-lg {
    margin-left: 4.5%;
  }

  .sm\:mx-p-lg {
    margin-left: 4.5%;
    margin-right: 4.5%;
  }

  .sm\:my-p-lg {
    margin-top: 4.5%;
    margin-bottom: 4.5%;
  }

  /*
    PADDING – p-lg
  */
  .sm\:p-p-lg {
    padding: 4.5%;
  }

  .sm\:pt-p-lg {
    padding-top: 4.5%;
  }

  .sm\:pr-p-lg {
    padding-right: 4.5%;
  }

  .sm\:pb-p-lg {
    padding-bottom: 4.5%;
  }

  .sm\:pl-p-lg {
    padding-left: 4.5%;
  }

  .sm\:px-p-lg {
    padding-left: 4.5%;
    padding-right: 4.5%;
  }

  .sm\:py-p-lg {
    padding-top: 4.5%;
    padding-bottom: 4.5%;
  }

  /*
    MARGIN – p-xl
  */
  .sm\:m-p-xl {
    margin: 7.5%;
  }

  .sm\:mt-p-xl {
    margin-top: 7.5%;
  }

  .sm\:mr-p-xl {
    margin-right: 7.5%;
  }

  .sm\:mb-p-xl {
    margin-bottom: 7.5%;
  }

  .sm\:ml-p-xl {
    margin-left: 7.5%;
  }

  .sm\:mx-p-xl {
    margin-left: 7.5%;
    margin-right: 7.5%;
  }

  .sm\:my-p-xl {
    margin-top: 7.5%;
    margin-bottom: 7.5%;
  }

  /*
    PADDING – p-xl
  */
  .sm\:p-p-xl {
    padding: 7.5%;
  }

  .sm\:pt-p-xl {
    padding-top: 7.5%;
  }

  .sm\:pr-p-xl {
    padding-right: 7.5%;
  }

  .sm\:pb-p-xl {
    padding-bottom: 7.5%;
  }

  .sm\:pl-p-xl {
    padding-left: 7.5%;
  }

  .sm\:px-p-xl {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }

  .sm\:py-p-xl {
    padding-top: 7.5%;
    padding-bottom: 7.5%;
  }

  /*
    MARGIN – push
  */
  .sm\:m-push {
    margin: calc(5vw + 75px) !important;
  }

  .sm\:mt-push {
    margin-top: calc(5vw + 75px) !important;
  }

  .sm\:mr-push {
    margin-right: calc(5vw + 75px) !important;
  }

  .sm\:mb-push {
    margin-bottom: calc(5vw + 75px) !important;
  }

  .sm\:ml-push {
    margin-left: calc(5vw + 75px) !important;
  }

  .sm\:mx-push {
    margin-left: calc(5vw + 75px) !important;
    margin-right: calc(5vw + 75px) !important;
  }

  .sm\:my-push {
    margin-top: calc(5vw + 75px) !important;
    margin-bottom: calc(5vw + 75px) !important;
  }

  /*
    PADDING – push
  */
  .sm\:p-push {
    padding: calc(5vw + 75px) !important;
  }

  .sm\:pt-push {
    padding-top: calc(5vw + 75px) !important;
  }

  .sm\:pr-push {
    padding-right: calc(5vw + 75px) !important;
  }

  .sm\:pb-push {
    padding-bottom: calc(5vw + 75px) !important;
  }

  .sm\:pl-push {
    padding-left: calc(5vw + 75px) !important;
  }

  .sm\:px-push {
    padding-left: calc(5vw + 75px) !important;
    padding-right: calc(5vw + 75px) !important;
  }

  .sm\:py-push {
    padding-top: calc(5vw + 75px) !important;
    padding-bottom: calc(5vw + 75px) !important;
  }
}
@media (min-width: 768px) {
  /*
    MARGIN – 0
  */
  .md\:m-0 {
    margin: 0;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mr-0 {
    margin-right: 0;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  /*
    PADDING – 0
  */
  .md\:p-0 {
    padding: 0;
  }

  .md\:pt-0 {
    padding-top: 0;
  }

  .md\:pr-0 {
    padding-right: 0;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }

  .md\:pl-0 {
    padding-left: 0;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  /*
    MARGIN – md
  */
  .md\:m-md {
    margin: 1rem;
  }

  .md\:mt-md {
    margin-top: 1rem;
  }

  .md\:mr-md {
    margin-right: 1rem;
  }

  .md\:mb-md {
    margin-bottom: 1rem;
  }

  .md\:ml-md {
    margin-left: 1rem;
  }

  .md\:mx-md {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .md\:my-md {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  /*
    PADDING – md
  */
  .md\:p-md {
    padding: 1rem;
  }

  .md\:pt-md {
    padding-top: 1rem;
  }

  .md\:pr-md {
    padding-right: 1rem;
  }

  .md\:pb-md {
    padding-bottom: 1rem;
  }

  .md\:pl-md {
    padding-left: 1rem;
  }

  .md\:px-md {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:py-md {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  /*
    MARGIN – lg
  */
  .md\:m-lg {
    margin: 2rem;
  }

  .md\:mt-lg {
    margin-top: 2rem;
  }

  .md\:mr-lg {
    margin-right: 2rem;
  }

  .md\:mb-lg {
    margin-bottom: 2rem;
  }

  .md\:ml-lg {
    margin-left: 2rem;
  }

  .md\:mx-lg {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .md\:my-lg {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  /*
    PADDING – lg
  */
  .md\:p-lg {
    padding: 2rem;
  }

  .md\:pt-lg {
    padding-top: 2rem;
  }

  .md\:pr-lg {
    padding-right: 2rem;
  }

  .md\:pb-lg {
    padding-bottom: 2rem;
  }

  .md\:pl-lg {
    padding-left: 2rem;
  }

  .md\:px-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:py-lg {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  /*
    MARGIN – xl
  */
  .md\:m-xl {
    margin: 4.5rem;
  }

  .md\:mt-xl {
    margin-top: 4.5rem;
  }

  .md\:mr-xl {
    margin-right: 4.5rem;
  }

  .md\:mb-xl {
    margin-bottom: 4.5rem;
  }

  .md\:ml-xl {
    margin-left: 4.5rem;
  }

  .md\:mx-xl {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }

  .md\:my-xl {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  /*
    PADDING – xl
  */
  .md\:p-xl {
    padding: 4.5rem;
  }

  .md\:pt-xl {
    padding-top: 4.5rem;
  }

  .md\:pr-xl {
    padding-right: 4.5rem;
  }

  .md\:pb-xl {
    padding-bottom: 4.5rem;
  }

  .md\:pl-xl {
    padding-left: 4.5rem;
  }

  .md\:px-xl {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }

  .md\:py-xl {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  /*
    MARGIN – p-lg
  */
  .md\:m-p-lg {
    margin: 4.5%;
  }

  .md\:mt-p-lg {
    margin-top: 4.5%;
  }

  .md\:mr-p-lg {
    margin-right: 4.5%;
  }

  .md\:mb-p-lg {
    margin-bottom: 4.5%;
  }

  .md\:ml-p-lg {
    margin-left: 4.5%;
  }

  .md\:mx-p-lg {
    margin-left: 4.5%;
    margin-right: 4.5%;
  }

  .md\:my-p-lg {
    margin-top: 4.5%;
    margin-bottom: 4.5%;
  }

  /*
    PADDING – p-lg
  */
  .md\:p-p-lg {
    padding: 4.5%;
  }

  .md\:pt-p-lg {
    padding-top: 4.5%;
  }

  .md\:pr-p-lg {
    padding-right: 4.5%;
  }

  .md\:pb-p-lg {
    padding-bottom: 4.5%;
  }

  .md\:pl-p-lg {
    padding-left: 4.5%;
  }

  .md\:px-p-lg {
    padding-left: 4.5%;
    padding-right: 4.5%;
  }

  .md\:py-p-lg {
    padding-top: 4.5%;
    padding-bottom: 4.5%;
  }

  /*
    MARGIN – p-xl
  */
  .md\:m-p-xl {
    margin: 7.5%;
  }

  .md\:mt-p-xl {
    margin-top: 7.5%;
  }

  .md\:mr-p-xl {
    margin-right: 7.5%;
  }

  .md\:mb-p-xl {
    margin-bottom: 7.5%;
  }

  .md\:ml-p-xl {
    margin-left: 7.5%;
  }

  .md\:mx-p-xl {
    margin-left: 7.5%;
    margin-right: 7.5%;
  }

  .md\:my-p-xl {
    margin-top: 7.5%;
    margin-bottom: 7.5%;
  }

  /*
    PADDING – p-xl
  */
  .md\:p-p-xl {
    padding: 7.5%;
  }

  .md\:pt-p-xl {
    padding-top: 7.5%;
  }

  .md\:pr-p-xl {
    padding-right: 7.5%;
  }

  .md\:pb-p-xl {
    padding-bottom: 7.5%;
  }

  .md\:pl-p-xl {
    padding-left: 7.5%;
  }

  .md\:px-p-xl {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }

  .md\:py-p-xl {
    padding-top: 7.5%;
    padding-bottom: 7.5%;
  }

  /*
    MARGIN – push
  */
  .md\:m-push {
    margin: calc(5vw + 75px) !important;
  }

  .md\:mt-push {
    margin-top: calc(5vw + 75px) !important;
  }

  .md\:mr-push {
    margin-right: calc(5vw + 75px) !important;
  }

  .md\:mb-push {
    margin-bottom: calc(5vw + 75px) !important;
  }

  .md\:ml-push {
    margin-left: calc(5vw + 75px) !important;
  }

  .md\:mx-push {
    margin-left: calc(5vw + 75px) !important;
    margin-right: calc(5vw + 75px) !important;
  }

  .md\:my-push {
    margin-top: calc(5vw + 75px) !important;
    margin-bottom: calc(5vw + 75px) !important;
  }

  /*
    PADDING – push
  */
  .md\:p-push {
    padding: calc(5vw + 75px) !important;
  }

  .md\:pt-push {
    padding-top: calc(5vw + 75px) !important;
  }

  .md\:pr-push {
    padding-right: calc(5vw + 75px) !important;
  }

  .md\:pb-push {
    padding-bottom: calc(5vw + 75px) !important;
  }

  .md\:pl-push {
    padding-left: calc(5vw + 75px) !important;
  }

  .md\:px-push {
    padding-left: calc(5vw + 75px) !important;
    padding-right: calc(5vw + 75px) !important;
  }

  .md\:py-push {
    padding-top: calc(5vw + 75px) !important;
    padding-bottom: calc(5vw + 75px) !important;
  }
}
@media (min-width: 992px) {
  /*
    MARGIN – 0
  */
  .lg\:m-0 {
    margin: 0;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mr-0 {
    margin-right: 0;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  /*
    PADDING – 0
  */
  .lg\:p-0 {
    padding: 0;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:pr-0 {
    padding-right: 0;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pl-0 {
    padding-left: 0;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  /*
    MARGIN – md
  */
  .lg\:m-md {
    margin: 1rem;
  }

  .lg\:mt-md {
    margin-top: 1rem;
  }

  .lg\:mr-md {
    margin-right: 1rem;
  }

  .lg\:mb-md {
    margin-bottom: 1rem;
  }

  .lg\:ml-md {
    margin-left: 1rem;
  }

  .lg\:mx-md {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .lg\:my-md {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  /*
    PADDING – md
  */
  .lg\:p-md {
    padding: 1rem;
  }

  .lg\:pt-md {
    padding-top: 1rem;
  }

  .lg\:pr-md {
    padding-right: 1rem;
  }

  .lg\:pb-md {
    padding-bottom: 1rem;
  }

  .lg\:pl-md {
    padding-left: 1rem;
  }

  .lg\:px-md {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:py-md {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  /*
    MARGIN – lg
  */
  .lg\:m-lg {
    margin: 2rem;
  }

  .lg\:mt-lg {
    margin-top: 2rem;
  }

  .lg\:mr-lg {
    margin-right: 2rem;
  }

  .lg\:mb-lg {
    margin-bottom: 2rem;
  }

  .lg\:ml-lg {
    margin-left: 2rem;
  }

  .lg\:mx-lg {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .lg\:my-lg {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  /*
    PADDING – lg
  */
  .lg\:p-lg {
    padding: 2rem;
  }

  .lg\:pt-lg {
    padding-top: 2rem;
  }

  .lg\:pr-lg {
    padding-right: 2rem;
  }

  .lg\:pb-lg {
    padding-bottom: 2rem;
  }

  .lg\:pl-lg {
    padding-left: 2rem;
  }

  .lg\:px-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-lg {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  /*
    MARGIN – xl
  */
  .lg\:m-xl {
    margin: 4.5rem;
  }

  .lg\:mt-xl {
    margin-top: 4.5rem;
  }

  .lg\:mr-xl {
    margin-right: 4.5rem;
  }

  .lg\:mb-xl {
    margin-bottom: 4.5rem;
  }

  .lg\:ml-xl {
    margin-left: 4.5rem;
  }

  .lg\:mx-xl {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }

  .lg\:my-xl {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  /*
    PADDING – xl
  */
  .lg\:p-xl {
    padding: 4.5rem;
  }

  .lg\:pt-xl {
    padding-top: 4.5rem;
  }

  .lg\:pr-xl {
    padding-right: 4.5rem;
  }

  .lg\:pb-xl {
    padding-bottom: 4.5rem;
  }

  .lg\:pl-xl {
    padding-left: 4.5rem;
  }

  .lg\:px-xl {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }

  .lg\:py-xl {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  /*
    MARGIN – p-lg
  */
  .lg\:m-p-lg {
    margin: 4.5%;
  }

  .lg\:mt-p-lg {
    margin-top: 4.5%;
  }

  .lg\:mr-p-lg {
    margin-right: 4.5%;
  }

  .lg\:mb-p-lg {
    margin-bottom: 4.5%;
  }

  .lg\:ml-p-lg {
    margin-left: 4.5%;
  }

  .lg\:mx-p-lg {
    margin-left: 4.5%;
    margin-right: 4.5%;
  }

  .lg\:my-p-lg {
    margin-top: 4.5%;
    margin-bottom: 4.5%;
  }

  /*
    PADDING – p-lg
  */
  .lg\:p-p-lg {
    padding: 4.5%;
  }

  .lg\:pt-p-lg {
    padding-top: 4.5%;
  }

  .lg\:pr-p-lg {
    padding-right: 4.5%;
  }

  .lg\:pb-p-lg {
    padding-bottom: 4.5%;
  }

  .lg\:pl-p-lg {
    padding-left: 4.5%;
  }

  .lg\:px-p-lg {
    padding-left: 4.5%;
    padding-right: 4.5%;
  }

  .lg\:py-p-lg {
    padding-top: 4.5%;
    padding-bottom: 4.5%;
  }

  /*
    MARGIN – p-xl
  */
  .lg\:m-p-xl {
    margin: 7.5%;
  }

  .lg\:mt-p-xl {
    margin-top: 7.5%;
  }

  .lg\:mr-p-xl {
    margin-right: 7.5%;
  }

  .lg\:mb-p-xl {
    margin-bottom: 7.5%;
  }

  .lg\:ml-p-xl {
    margin-left: 7.5%;
  }

  .lg\:mx-p-xl {
    margin-left: 7.5%;
    margin-right: 7.5%;
  }

  .lg\:my-p-xl {
    margin-top: 7.5%;
    margin-bottom: 7.5%;
  }

  /*
    PADDING – p-xl
  */
  .lg\:p-p-xl {
    padding: 7.5%;
  }

  .lg\:pt-p-xl {
    padding-top: 7.5%;
  }

  .lg\:pr-p-xl {
    padding-right: 7.5%;
  }

  .lg\:pb-p-xl {
    padding-bottom: 7.5%;
  }

  .lg\:pl-p-xl {
    padding-left: 7.5%;
  }

  .lg\:px-p-xl {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }

  .lg\:py-p-xl {
    padding-top: 7.5%;
    padding-bottom: 7.5%;
  }

  /*
    MARGIN – push
  */
  .lg\:m-push {
    margin: calc(5vw + 75px) !important;
  }

  .lg\:mt-push {
    margin-top: calc(5vw + 75px) !important;
  }

  .lg\:mr-push {
    margin-right: calc(5vw + 75px) !important;
  }

  .lg\:mb-push {
    margin-bottom: calc(5vw + 75px) !important;
  }

  .lg\:ml-push {
    margin-left: calc(5vw + 75px) !important;
  }

  .lg\:mx-push {
    margin-left: calc(5vw + 75px) !important;
    margin-right: calc(5vw + 75px) !important;
  }

  .lg\:my-push {
    margin-top: calc(5vw + 75px) !important;
    margin-bottom: calc(5vw + 75px) !important;
  }

  /*
    PADDING – push
  */
  .lg\:p-push {
    padding: calc(5vw + 75px) !important;
  }

  .lg\:pt-push {
    padding-top: calc(5vw + 75px) !important;
  }

  .lg\:pr-push {
    padding-right: calc(5vw + 75px) !important;
  }

  .lg\:pb-push {
    padding-bottom: calc(5vw + 75px) !important;
  }

  .lg\:pl-push {
    padding-left: calc(5vw + 75px) !important;
  }

  .lg\:px-push {
    padding-left: calc(5vw + 75px) !important;
    padding-right: calc(5vw + 75px) !important;
  }

  .lg\:py-push {
    padding-top: calc(5vw + 75px) !important;
    padding-bottom: calc(5vw + 75px) !important;
  }
}
@media (min-width: 1200px) {
  /*
    MARGIN – 0
  */
  .xl\:m-0 {
    margin: 0;
  }

  .xl\:mt-0 {
    margin-top: 0;
  }

  .xl\:mr-0 {
    margin-right: 0;
  }

  .xl\:mb-0 {
    margin-bottom: 0;
  }

  .xl\:ml-0 {
    margin-left: 0;
  }

  .xl\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .xl\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  /*
    PADDING – 0
  */
  .xl\:p-0 {
    padding: 0;
  }

  .xl\:pt-0 {
    padding-top: 0;
  }

  .xl\:pr-0 {
    padding-right: 0;
  }

  .xl\:pb-0 {
    padding-bottom: 0;
  }

  .xl\:pl-0 {
    padding-left: 0;
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  /*
    MARGIN – md
  */
  .xl\:m-md {
    margin: 1rem;
  }

  .xl\:mt-md {
    margin-top: 1rem;
  }

  .xl\:mr-md {
    margin-right: 1rem;
  }

  .xl\:mb-md {
    margin-bottom: 1rem;
  }

  .xl\:ml-md {
    margin-left: 1rem;
  }

  .xl\:mx-md {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .xl\:my-md {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  /*
    PADDING – md
  */
  .xl\:p-md {
    padding: 1rem;
  }

  .xl\:pt-md {
    padding-top: 1rem;
  }

  .xl\:pr-md {
    padding-right: 1rem;
  }

  .xl\:pb-md {
    padding-bottom: 1rem;
  }

  .xl\:pl-md {
    padding-left: 1rem;
  }

  .xl\:px-md {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xl\:py-md {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  /*
    MARGIN – lg
  */
  .xl\:m-lg {
    margin: 2rem;
  }

  .xl\:mt-lg {
    margin-top: 2rem;
  }

  .xl\:mr-lg {
    margin-right: 2rem;
  }

  .xl\:mb-lg {
    margin-bottom: 2rem;
  }

  .xl\:ml-lg {
    margin-left: 2rem;
  }

  .xl\:mx-lg {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .xl\:my-lg {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  /*
    PADDING – lg
  */
  .xl\:p-lg {
    padding: 2rem;
  }

  .xl\:pt-lg {
    padding-top: 2rem;
  }

  .xl\:pr-lg {
    padding-right: 2rem;
  }

  .xl\:pb-lg {
    padding-bottom: 2rem;
  }

  .xl\:pl-lg {
    padding-left: 2rem;
  }

  .xl\:px-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xl\:py-lg {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  /*
    MARGIN – xl
  */
  .xl\:m-xl {
    margin: 4.5rem;
  }

  .xl\:mt-xl {
    margin-top: 4.5rem;
  }

  .xl\:mr-xl {
    margin-right: 4.5rem;
  }

  .xl\:mb-xl {
    margin-bottom: 4.5rem;
  }

  .xl\:ml-xl {
    margin-left: 4.5rem;
  }

  .xl\:mx-xl {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }

  .xl\:my-xl {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  /*
    PADDING – xl
  */
  .xl\:p-xl {
    padding: 4.5rem;
  }

  .xl\:pt-xl {
    padding-top: 4.5rem;
  }

  .xl\:pr-xl {
    padding-right: 4.5rem;
  }

  .xl\:pb-xl {
    padding-bottom: 4.5rem;
  }

  .xl\:pl-xl {
    padding-left: 4.5rem;
  }

  .xl\:px-xl {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }

  .xl\:py-xl {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  /*
    MARGIN – p-lg
  */
  .xl\:m-p-lg {
    margin: 4.5%;
  }

  .xl\:mt-p-lg {
    margin-top: 4.5%;
  }

  .xl\:mr-p-lg {
    margin-right: 4.5%;
  }

  .xl\:mb-p-lg {
    margin-bottom: 4.5%;
  }

  .xl\:ml-p-lg {
    margin-left: 4.5%;
  }

  .xl\:mx-p-lg {
    margin-left: 4.5%;
    margin-right: 4.5%;
  }

  .xl\:my-p-lg {
    margin-top: 4.5%;
    margin-bottom: 4.5%;
  }

  /*
    PADDING – p-lg
  */
  .xl\:p-p-lg {
    padding: 4.5%;
  }

  .xl\:pt-p-lg {
    padding-top: 4.5%;
  }

  .xl\:pr-p-lg {
    padding-right: 4.5%;
  }

  .xl\:pb-p-lg {
    padding-bottom: 4.5%;
  }

  .xl\:pl-p-lg {
    padding-left: 4.5%;
  }

  .xl\:px-p-lg {
    padding-left: 4.5%;
    padding-right: 4.5%;
  }

  .xl\:py-p-lg {
    padding-top: 4.5%;
    padding-bottom: 4.5%;
  }

  /*
    MARGIN – p-xl
  */
  .xl\:m-p-xl {
    margin: 7.5%;
  }

  .xl\:mt-p-xl {
    margin-top: 7.5%;
  }

  .xl\:mr-p-xl {
    margin-right: 7.5%;
  }

  .xl\:mb-p-xl {
    margin-bottom: 7.5%;
  }

  .xl\:ml-p-xl {
    margin-left: 7.5%;
  }

  .xl\:mx-p-xl {
    margin-left: 7.5%;
    margin-right: 7.5%;
  }

  .xl\:my-p-xl {
    margin-top: 7.5%;
    margin-bottom: 7.5%;
  }

  /*
    PADDING – p-xl
  */
  .xl\:p-p-xl {
    padding: 7.5%;
  }

  .xl\:pt-p-xl {
    padding-top: 7.5%;
  }

  .xl\:pr-p-xl {
    padding-right: 7.5%;
  }

  .xl\:pb-p-xl {
    padding-bottom: 7.5%;
  }

  .xl\:pl-p-xl {
    padding-left: 7.5%;
  }

  .xl\:px-p-xl {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }

  .xl\:py-p-xl {
    padding-top: 7.5%;
    padding-bottom: 7.5%;
  }

  /*
    MARGIN – push
  */
  .xl\:m-push {
    margin: calc(5vw + 75px) !important;
  }

  .xl\:mt-push {
    margin-top: calc(5vw + 75px) !important;
  }

  .xl\:mr-push {
    margin-right: calc(5vw + 75px) !important;
  }

  .xl\:mb-push {
    margin-bottom: calc(5vw + 75px) !important;
  }

  .xl\:ml-push {
    margin-left: calc(5vw + 75px) !important;
  }

  .xl\:mx-push {
    margin-left: calc(5vw + 75px) !important;
    margin-right: calc(5vw + 75px) !important;
  }

  .xl\:my-push {
    margin-top: calc(5vw + 75px) !important;
    margin-bottom: calc(5vw + 75px) !important;
  }

  /*
    PADDING – push
  */
  .xl\:p-push {
    padding: calc(5vw + 75px) !important;
  }

  .xl\:pt-push {
    padding-top: calc(5vw + 75px) !important;
  }

  .xl\:pr-push {
    padding-right: calc(5vw + 75px) !important;
  }

  .xl\:pb-push {
    padding-bottom: calc(5vw + 75px) !important;
  }

  .xl\:pl-push {
    padding-left: calc(5vw + 75px) !important;
  }

  .xl\:px-push {
    padding-left: calc(5vw + 75px) !important;
    padding-right: calc(5vw + 75px) !important;
  }

  .xl\:py-push {
    padding-top: calc(5vw + 75px) !important;
    padding-bottom: calc(5vw + 75px) !important;
  }
}
/*
  STACK – 0
*/
.stack-0 > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
  margin-top: 0;
}

/*
  STACK – md
*/
.stack-md > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
  margin-top: 1rem;
}

/*
  STACK – lg
*/
.stack-lg > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
  margin-top: 2rem;
}

/*
  STACK – xl
*/
.stack-xl > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
  margin-top: 4.5rem;
}

/*
  STACK – p-lg
*/
.stack-p-lg > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
  margin-top: 4.5%;
}

/*
  STACK – p-xl
*/
.stack-p-xl > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
  margin-top: 7.5%;
}

/*
  STACK – push
*/
.stack-push > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
  margin-top: calc(5vw + 75px) !important;
}

@media (min-width: 576px) {
  /*
    STACK – 0
  */
  .sm\:stack-0 > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 0;
  }

  /*
    STACK – md
  */
  .sm\:stack-md > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 1rem;
  }

  /*
    STACK – lg
  */
  .sm\:stack-lg > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 2rem;
  }

  /*
    STACK – xl
  */
  .sm\:stack-xl > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 4.5rem;
  }

  /*
    STACK – p-lg
  */
  .sm\:stack-p-lg > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 4.5%;
  }

  /*
    STACK – p-xl
  */
  .sm\:stack-p-xl > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 7.5%;
  }

  /*
    STACK – push
  */
  .sm\:stack-push > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: calc(5vw + 75px) !important;
  }
}
@media (min-width: 768px) {
  /*
    STACK – 0
  */
  .md\:stack-0 > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 0;
  }

  /*
    STACK – md
  */
  .md\:stack-md > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 1rem;
  }

  /*
    STACK – lg
  */
  .md\:stack-lg > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 2rem;
  }

  /*
    STACK – xl
  */
  .md\:stack-xl > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 4.5rem;
  }

  /*
    STACK – p-lg
  */
  .md\:stack-p-lg > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 4.5%;
  }

  /*
    STACK – p-xl
  */
  .md\:stack-p-xl > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 7.5%;
  }

  /*
    STACK – push
  */
  .md\:stack-push > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: calc(5vw + 75px) !important;
  }
}
@media (min-width: 992px) {
  /*
    STACK – 0
  */
  .lg\:stack-0 > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 0;
  }

  /*
    STACK – md
  */
  .lg\:stack-md > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 1rem;
  }

  /*
    STACK – lg
  */
  .lg\:stack-lg > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 2rem;
  }

  /*
    STACK – xl
  */
  .lg\:stack-xl > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 4.5rem;
  }

  /*
    STACK – p-lg
  */
  .lg\:stack-p-lg > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 4.5%;
  }

  /*
    STACK – p-xl
  */
  .lg\:stack-p-xl > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 7.5%;
  }

  /*
    STACK – push
  */
  .lg\:stack-push > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: calc(5vw + 75px) !important;
  }
}
@media (min-width: 1200px) {
  /*
    STACK – 0
  */
  .xl\:stack-0 > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 0;
  }

  /*
    STACK – md
  */
  .xl\:stack-md > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 1rem;
  }

  /*
    STACK – lg
  */
  .xl\:stack-lg > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 2rem;
  }

  /*
    STACK – xl
  */
  .xl\:stack-xl > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 4.5rem;
  }

  /*
    STACK – p-lg
  */
  .xl\:stack-p-lg > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 4.5%;
  }

  /*
    STACK – p-xl
  */
  .xl\:stack-p-xl > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: 7.5%;
  }

  /*
    STACK – push
  */
  .xl\:stack-push > * + *:not([class*=m-]):not([class*=mt-]):not([class*=mb-]):not([class*=my-]) {
    margin-top: calc(5vw + 75px) !important;
  }
}
/*
  Body sizes
*/
body {
  font-size: 1.0625rem;
}
@media (min-width: 768px) {
  body {
    font-size: 1rem;
  }
}

/*
  Font sizes
*/
.fs-12 {
  font-size: 0.75rem;
}

.fs-14 {
  font-size: 0.875rem;
}

.fs-16 {
  font-size: 1rem;
}

.fs-17 {
  font-size: 1.0625rem;
}

.fs-25 {
  font-size: 1.5625rem;
}

.fs-35 {
  font-size: 2.1875rem;
}

@media (min-width: 576px) {
  .sm\:fs-12 {
    font-size: 0.75rem;
  }

  .sm\:fs-14 {
    font-size: 0.875rem;
  }

  .sm\:fs-16 {
    font-size: 1rem;
  }

  .sm\:fs-17 {
    font-size: 1.0625rem;
  }

  .sm\:fs-25 {
    font-size: 1.5625rem;
  }

  .sm\:fs-35 {
    font-size: 2.1875rem;
  }
}
@media (min-width: 768px) {
  .md\:fs-12 {
    font-size: 0.75rem;
  }

  .md\:fs-14 {
    font-size: 0.875rem;
  }

  .md\:fs-16 {
    font-size: 1rem;
  }

  .md\:fs-17 {
    font-size: 1.0625rem;
  }

  .md\:fs-25 {
    font-size: 1.5625rem;
  }

  .md\:fs-35 {
    font-size: 2.1875rem;
  }
}
@media (min-width: 992px) {
  .lg\:fs-12 {
    font-size: 0.75rem;
  }

  .lg\:fs-14 {
    font-size: 0.875rem;
  }

  .lg\:fs-16 {
    font-size: 1rem;
  }

  .lg\:fs-17 {
    font-size: 1.0625rem;
  }

  .lg\:fs-25 {
    font-size: 1.5625rem;
  }

  .lg\:fs-35 {
    font-size: 2.1875rem;
  }
}
@media (min-width: 1200px) {
  .xl\:fs-12 {
    font-size: 0.75rem;
  }

  .xl\:fs-14 {
    font-size: 0.875rem;
  }

  .xl\:fs-16 {
    font-size: 1rem;
  }

  .xl\:fs-17 {
    font-size: 1.0625rem;
  }

  .xl\:fs-25 {
    font-size: 1.5625rem;
  }

  .xl\:fs-35 {
    font-size: 2.1875rem;
  }
}
/*
  Display sizes
*/
.rich-text h1,
.collage .rich-text h2,
.display-1 {
  font-size: 1.5625rem;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .rich-text h1,
.collage .rich-text h2,
.display-1 {
    text-transform: uppercase;
  }
}
@media (min-width: 1200px) {
  .rich-text h1,
.collage .rich-text h2,
.display-1 {
    font-size: 2.1875rem;
    text-transform: uppercase;
  }
}
@media (max-width: 575px) {
  .rich-text h1,
.collage .rich-text h2,
.display-1 {
    hyphens: auto;
  }
}

.rich-text h2,
.display-2 {
  text-transform: uppercase;
}

/*
  Font Weights
*/
.fw-normal {
  font-weight: 400;
}

.fw-bold {
  font-weight: 500;
}

/*
  Transforms
*/
.tt-uppercase {
  text-transform: uppercase;
}

/*
  Text Alignment
*/
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

@media (min-width: 576px) {
  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:text-right {
    text-align: right;
  }
}
@media (min-width: 768px) {
  .md\:text-left {
    text-align: left;
  }

  .md\:text-center {
    text-align: center;
  }

  .md\:text-right {
    text-align: right;
  }
}
@media (min-width: 992px) {
  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-right {
    text-align: right;
  }
}
@media (min-width: 1200px) {
  .xl\:text-left {
    text-align: left;
  }

  .xl\:text-center {
    text-align: center;
  }

  .xl\:text-right {
    text-align: right;
  }
}
.fade {
  transition: opacity 0.15s linear;
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.pulse {
  animation: pulse 2s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(-10%);
  }
}
.container-full {
  width: 100%;
}

.container-main {
  max-width: 1640px;
  margin-left: auto;
  margin-right: auto;
}

.container-main {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 576px) {
  .container-main {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (min-width: 768px) {
  .container-main {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 992px) {
  .container-main {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .container-main {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
.float-right {
  float: right;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.spin {
  animation: spin 0.75s linear infinite;
}